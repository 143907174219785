import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

import _ from "lodash";
import { toast } from "react-toastify";

import AddInvoiceReceiptModal from "components/Modals/addInvoiceReceiptModal";
import StatusUpdateModal from "components/Modals/statusUpdateModal";
import PdfViewerModal from "components/Modals/pdfViewerModal";

import { useStatusData } from "hooks/useStatusData";
import { updateInvoiceStatus } from "views/examples/_actions";
import { getNoOfDaysFromCurrentDate } from "utils/dateUtils";
import {
  downloadInvoiceDoc,
  downloadInvoicePdf,
} from "views/examples/_actions";
import { downloadFile } from "actions/commonActions";
import TransactionTable from "components/tables/transactionTable";
import StatementOfAccount from "./statementOfAccount";

const InvoiceCard = (props) => {
  const { invoices, type, transc } = props;

  const { userRights, currentCompany } = props;
  const [canEdit, setCanEdit] = useState(false);
  const [openReceiptModal, setOpenReceiptModal] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([]);
  const [selectedInvoiceItem, setSelectedInvoiceItem] = useState(null);
  const [showStatusEditPopup, setShowStatusEditPopup] = useState(false);
  const [initializedData, setInitializedData] = useState(false);
  const [showInvoicePdf, setShowInvoicePdf] = useState(false);
  const [invoiceFileUrl, setInvoiceFileUrl] = useState("");
  const { data: statusList } = useStatusData(7);

  useEffect(() => {
    if (invoices && invoices.length > 0) {
      invoices.forEach((invoice) => {
        invoice.bil_cat_desc = invoice.cat_desc;

        invoice.bil_statusid = invoice.bil_status;
        invoice.bill_id = "";
        setInitializedData(true);
      });
    }
  }, [invoices]);

  useEffect(() => {
    userRights?.forEach((item) => {
      if (item?.menu_type_id === "module_1") {
        if (item?.update === "1") {
          setCanEdit(true);
        }
      }
    });
  }, [userRights]);
  const openInvoiceImage = async (val, _type) => {
    try {
      const res = await downloadInvoiceDoc(val);
      const fileUrl = res?.data.data[0].doc_url;
      if (fileUrl) {
        downloadFile(fileUrl, `${val}.pdf`);
      } else {
        toast.error("Invoice File not found");
      }
    } catch (error) {
      console.error("Error downloading invoice:", error);
      toast.error("Failed to download invoice");
    }
  };

  const openUploadReceiptModal = () => {
    setOpenReceiptModal(!openReceiptModal);
  };

  let transactions = [];

  const transactionSelectionHandler = (transaction, selected) => {
    if (selected) {
      transactions.push(transaction);
    } else {
      _.remove(transactions, {
        bil_docno: transaction.bil_docno,
      });
    }

    setSelectedInvoices([...transactions]);
  };

  const updateStatusHandler = (invoice) => {
    setSelectedInvoiceItem(invoice);
    setShowStatusEditPopup(!showStatusEditPopup);
  };

  const statusCloseHandler = async (status) => {
    if (status) {
      let invoice = { ...selectedInvoiceItem };
      invoice.bil_status = status?.id;
      setSelectedInvoiceItem(invoice);
    }
    setShowStatusEditPopup(false);

    const response = await updateInvoiceStatus({
      bill_id: selectedInvoiceItem.bil_docno,
      bill_status: selectedInvoiceItem.bil_status,
    });
  };

  const viewPDFHandler = async (invoice) => {
    const res = await downloadInvoiceDoc(invoice?.bil_docno);

    const fileUrl = res?.data?.data[0].doc_url;

    if (fileUrl !== "" && fileUrl !== null) {
      downloadFile(fileUrl, `${invoice?.bil_docno}.pdf`);
    } else {
      toast.error("Invoice File not found");
    }
  };
  const invoicePdfCloser = () => {
    setShowInvoicePdf(false);
  };
  const viewInvoicePDFHandler = async () => {
    const res = await downloadInvoicePdf(currentCompany);
    if (res?.data?.data[0]) {
      const fileUrl = res?.data?.data[0].doc_link;
      setInvoiceFileUrl(fileUrl);

      if (fileUrl !== "" && fileUrl !== null) {
        setShowInvoicePdf(true);
      } else {
        toast.error("Invoice File not found");
      }
    }
  };

  const statusRender = (invoice) => {
    let selectedStatus = null;
    statusList.forEach((status) => {
      if (status.id === invoice.bil_status) {
        selectedStatus = status;
      }
    });

    if (selectedStatus) {
      return selectedStatus.name;
    }
  };

  const noOfDaysRender = (invoice) => {
    const days = getNoOfDaysFromCurrentDate(new Date(invoice.bil_datetime));
    return days;
  };

  return (
    <>
      <Card className="dashboard-card-own revenue-card-wrapper">
        <CardHeader className="card-header-own">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="font-15 color-primary weight-600">
              {type} Invoices
            </div>
            {/* <button
							className="listing-btn mr-3"
							onClick={() => props?.history?.push("/admin/auto_invoices")}
						>
							Auto Invoice
						</button> */}
            <div style={{ display: "flex" }}>
              {selectedInvoices && selectedInvoices.length > 0 && (
                <div>
                  <button
                    className="listing-btn mr-3"
                    onClick={openUploadReceiptModal}
                  >
                    Add Receipt <i className="fas fa-arrow-right"></i>
                  </button>
                </div>
              )}
              <StatementOfAccount
                currentCompany={currentCompany}
                invoices={invoices}
                viewInvoicePDFHandler={() => {
                  viewInvoicePDFHandler();
                }}
              />
            </div>
          </div>
        </CardHeader>
        {transc && (
          <CardBody className="pt-3">
            {statusList && statusList.length > 0 && initializedData ? (
              <>
                <div>
                  <TransactionTable
                    showHeader={false}
                    statusList={statusList}
                    tableData={invoices}
                    canEdit={canEdit}
                    openImage={openInvoiceImage}
                    updateStatus={() => {}}
                    sendNotification={() => {}}
                    onTransactionSelect={transactionSelectionHandler}
                  />
                </div>
                {type === "Pending" && (
                  <div className="font-13 color-primary weight-600 d-flex justify-content-between pt-3">
                    <div>
                      {/* {InvoiceCard?.length} Pending{" "}
                      {InvoiceCard?.length === 1 ? "Payment" : "Payments"} */}
                    </div>
                    <div>
                      <button
                        className="listing-btn"
                        onClick={() =>
                          props?.history?.push("/admin/invoice_management")
                        }
                      >
                        View All <i className="fas fa-arrow-right"></i>
                      </button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-5 pt-4 color-primary d-flex justify-content-center align-items-center">
                Data Not Available
              </div>
            )}
          </CardBody>
        )}
      </Card>

      {openReceiptModal && (
        <AddInvoiceReceiptModal
          open={openReceiptModal}
          selectedInvoices={selectedInvoices}
          statusList={statusList}
          onCloseModal={() => {
            setSelectedInvoices([]);
            setOpenReceiptModal(!openReceiptModal);
          }}
        />
      )}

      {showStatusEditPopup && (
        <StatusUpdateModal
          open={showStatusEditPopup}
          onCloseModal={statusCloseHandler}
          statusList={statusList}
          formData={selectedInvoiceItem?.bil_status}
        />
      )}
      {showInvoicePdf && (
        <PdfViewerModal
          open={showInvoicePdf}
          onCloseModal={invoicePdfCloser}
          fileUrl={invoiceFileUrl}
          currentCompany={currentCompany}
          type={type}
        />
      )}
    </>
  );
};

export default InvoiceCard;
