import { createStore, combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import commonReducer from "../reducers/commonReducers";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
};

let reducer = combineReducers({
  common: commonReducer,
});

const persistedReducer = persistReducer(persistConfig, reducer);

export const appStore = createStore(persistedReducer);
export const persistor = persistStore(appStore);
