import { CURRENT_COMPANY } from "actionTypes/types";
import { COMPANY_API_DATA } from "actionTypes/types";
import {
  PROFILE_API_DATA,
  GLOBAL_LOADER,
  ROLE_API_DATA,
  LISTING_TABLE_DATA,
} from "../actionTypes/types";

const INITIAL_STATE = {
  loader: false,
  roleData: null,
  profileData: null,
  companyData: null,
  listingTableData: null,
  currentCompany: null,
};

const commonReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_LOADER: {
      return {
        ...state,
        loader: action.payload,
      };
    }
    case ROLE_API_DATA: {
      return { ...state, roleData: action.payload };
    }
    case PROFILE_API_DATA: {
      return { ...state, profileData: action.payload };
    }
    case COMPANY_API_DATA: {
      return { ...state, companyData: action.payload };
    }
    case LISTING_TABLE_DATA: {
      return { ...state, listingTableData: action.payload };
    }
    case CURRENT_COMPANY: {
      return { ...state, currentCompany: action.payload };
    }
    default:
      return state;
  }
};

export default commonReducer;
