import React from "react";
import { connect } from "react-redux";

const Loader = (props) => {
  return (
    // <React.Fragment>
    //   {props.loader ? (
    //     <div className="loader-wrapper">
    //       <div className="loader"></div>
    //     </div>
    //   ) : null}
    // </React.Fragment>
    <React.Fragment>
      {props?.loader ? (
        <div className="loader-wrapper">
          <div className="cs-loader">
            <div className="cs-loader-inner">
              <div className="d-flex justify-content-center">
                Loading, Please Wait...
              </div>
              <div>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
                <label>●</label>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
};

const mapState = (state) => ({
  loader: state.common.loader,
});

export default connect(mapState)(Loader);
