// export const GLOBAL_API = "https://api-dev.avisksa.com/api/AvisCorpAppPortal";
export const GLOBAL_API = "https://api2.avisksa.com/api/AvisCorpAppPortal";
export const GLOBAL_LOADER = "GLOBAL_LOADER";
export const ROLE_API_DATA = "ROLE_API_DATA";
export const PROFILE_API_DATA = "PROFILE_API_DATA";
export const COMPANY_API_DATA = "COMPANY_API_DATA";
export const LISTING_TABLE_DATA = "LISTING_TABLE_DATA";
export const CURRENT_COMPANY = "CURRENT_COMPANY";
export const TOKEN_USERNAME = "U2FsdGVkX1+4z+AhNlR4PyFzmByypQPveYtwWgj7P1A=";
export const TOKEN_PASSWORD = "U2FsdGVkX1+fZMgjnCaXk0vOYNAQH/inbTiVo9AvkFA=";
export const TOKEN_GRANT_TYPE = "U2FsdGVkX19F3592WDWtgUzY031CFGGghByaNIRh9CY=";
export const SECRET = "Star Platinum";
export const dateOptions = {
  day: "numeric",
  year: "numeric",
  month: "short",
  hour12: true,
  hour: "2-digit",
  minute: "2-digit",
};
export const MAX_IMG_SIZE = 1024000;
export const UPLOAD_IMAGE = "../assets/wallpaper/upload-image.png";
